import { createBrowserRouter, type RouteObject } from 'react-router-dom';
import type { IVault } from '@services/VaultsStore';
import { wait } from '@utils/general';

enum SerafFlatRoute {
	ROOT = '/',
	SIGN_IN = '/sign-in',
	MFA_SETUP = '/mfa-require',
	AUTH_CALLBACK = '/auth/callback',
	PROFILE = '/app/profile',
	TOOLS_FIX_INTEGRITY = '/app/settings/tools/fix-integrity',
	TOOLS_QDRANT_SPARSE = '/app/settings/tools/qdrant-sparse',
	PERSONAL_INFO = '/app/profile/personal-info',
	YOUR_SERAF = '/app/profile/seraf',
	CHANGE_PASSWORD = '/app/profile/change-password',
	PROFILE_SECURITY = '/app/profile/security',
	DATA_UPLOAD = '/data/upload',
	DATA = '/app/data',
	VAULTS = '/app/vaults',
	DATA_STORAGE = '/app/storage',
	DATA_STORAGE_SHARED = '/app/storage/shared',
	DATA_STORAGE_ALL = '/app/storage/all',
	DATA_STORAGE_MAPPING = '/app/storage/mapping',
	DATA_STORAGE_VAULT = '/app/storage/:vaultId',
	USERS = '/app/users',
	ROLES_GENERAL = '/app/roles/general',
	ROLES_PERMISSIONS = '/app/roles/permissions',
	ROLES_VAULTS = '/app/roles/vaults',
	CONNECTIONS = '/app/connections',
	CONNECTION_NEW = '/app/connections/new',
	CONNECTION_GDRIVE = '/app/connections/drive/:email/',
	CONNECTION_ONEDRIVE = '/app/connections/onedrive/:sourceId/',
	CONNECTION_SHAREPOINT = '/app/connections/sharepoint/:sourceId/',
	CONNECTION_ACUMATICA = '/app/connections/acumatica/:sourceId/',
	CONNECTION_GMAIL = '/app/connections/mail',
	CONNECTION_ALANYTICS = '/app/connections/analytics',
	CONNECTION_NOTION = '/app/connections/notion/:sourceId',
	CHAT = '/chat',
	APPEARANCE = '/app/settings/appearance',
	SYSTEM = '/app/settings/system',
	CURRENCY = '/app/settings/currency',
	HISTORY = '/app/history',
	DASHBOARD = '/chat/dashboard',
	TOPIC = '/chat/:topicId',
	TOPIC_DOCUMENT = '/chat/:topicId/d/:documentId',
	DOCUMENT_DASHBOARD = '/chat/document/:documentId',
	CHAT_WITH_VAULT = '/chat/vault/:vaultId',
	TEMPLATES = '/app/templates',
	TEMPLATES_BUILDER = '/app/templates/editor/:templateId?',
	CUSTOM_RULES = '/app/business-rules',
	RESOLVER = '/app/resolver',
	RESOLVER_LIBRARY = '/app/resolver/library',
	RESOLVER_TYPE = '/app/resolver/type/:typeId',
	RESOLVER_TEST_PAGE = '/app/resolver/tests',
}

export const SerafRoute = {
	...SerafFlatRoute,
	TOPIC: (topicId: string) => SerafFlatRoute.TOPIC.replace(':topicId', topicId),

	TOPIC_DOCUMENT: (topicId: string, documentId: number) =>
		SerafFlatRoute.TOPIC_DOCUMENT.replace(':topicId', topicId).replace(':documentId', String(documentId)),

	DOCUMENT_DASHBOARD: (documentId: number) =>
		SerafFlatRoute.DOCUMENT_DASHBOARD.replace(':documentId', String(documentId)),

	TEMPLATES_BUILDER: (templateId?: string) => SerafFlatRoute.TEMPLATES_BUILDER.replace(':templateId', templateId ?? ''),

	CONNECTION_NOTION: (sourceId: string) => SerafFlatRoute.CONNECTION_NOTION.replace(':sourceId', sourceId),

	CONNECTION_ONEDRIVE: (sourceId: string) => SerafFlatRoute.CONNECTION_ONEDRIVE.replace(':sourceId', sourceId),

	RESOLVER_TYPE: (typeId: number) => SerafFlatRoute.RESOLVER_TYPE.replace(':typeId', typeId.toString()),
	DATA_STORAGE_VAULT: (vaultId: IVault['id']) => SerafFlatRoute.DATA_STORAGE_VAULT.replace(':vaultId', String(vaultId)),

	CONNECTION_SHAREPOINT: (sourceId: string) => SerafFlatRoute.CONNECTION_SHAREPOINT.replace(':sourceId', sourceId),

	CONNECTION_ACUMATICA: (sourceId: string) => SerafFlatRoute.CONNECTION_ACUMATICA.replace(':sourceId', sourceId),
	
	CHAT_WITH_VAULT: (vaultId: IVault['id']) => SerafFlatRoute.CHAT_WITH_VAULT.replace(':vaultId', String(vaultId)),

	DATA_UPLOAD_VAULT: (vaultId: IVault['id']) => `${SerafFlatRoute.DATA_UPLOAD}?vaultId=${vaultId}`,
} as const;

const routes: RouteObject[] = [
	{
		path: SerafFlatRoute.SIGN_IN,
		async lazy() {
			const component = await import('@pages/login-form/LoginForm');
			return { Component: component.default };
		},
	},
	{
		path: SerafFlatRoute.MFA_SETUP,
		async lazy() {
			const component = await import('@pages/login-form/steps/Mfa/RequireMfa');
			return { Component: component.default };
		},
	},
	{
		path: SerafFlatRoute.AUTH_CALLBACK,
		async lazy() {
			const component = await import('@pages/auth-callback/AuthCallback');
			return { Component: component.default };
		},
	},
	{
		path: SerafFlatRoute.ROOT,
		async lazy() {
			const component = await import('./Root');
			return { Component: component.default };
		},
		children: [
			{
				path: '/app',
				async lazy() {
					const component = await import('@layout/inner-page/InnerPage');
					return { Component: component.default };
				},
				children: [
					{
						path: SerafFlatRoute.TOOLS_FIX_INTEGRITY,
						async lazy() {
							const component = await import('@pages/tools/FixIntegrity');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.TOOLS_QDRANT_SPARSE,
						async lazy() {
							const component = await import('@pages/tools/QDrantSparse');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.PERSONAL_INFO,
						async lazy() {
							const component = await import('@pages/profile/profile/Profile');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.YOUR_SERAF,
						async lazy() {
							const component = await import('@pages/profile/bot-settings/BotSettings');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.PROFILE_SECURITY,
						async lazy() {
							const component = await import('@pages/profile/security/Security');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.CHANGE_PASSWORD,
						async lazy() {
							const component = await import('@pages/profile/change-password/ChangePassword');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.DATA_STORAGE,
						async lazy() {
							const component = await import('@pages/data-storage/DataStorage');

							return { Component: component.default };
						},
						children: [
							{
								path: SerafFlatRoute.DATA_STORAGE_MAPPING,
								async lazy() {
									const component = await import('@pages/data-storage/VaultsMapping/VaultsMapping');

									return { Component: component.default };
								},
							},
							{
								path: SerafFlatRoute.DATA_STORAGE_VAULT,
								async lazy() {
									const component = await import('@pages/data-storage/VaultWrapper/VaultWrapper');

									return { Component: component.default };
								},
							},
						],
					},
					{
						path: SerafFlatRoute.USERS,
						async lazy() {
							const component = await import('@pages/members/Members');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.ROLES_GENERAL,
						async lazy() {
							const component = await import('@pages/roles/components/RolesGeneral/RolesGeneral');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.ROLES_PERMISSIONS,
						async lazy() {
							const component = await import('@pages/roles/components/RolesPermissions/RolesPermissions');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.ROLES_VAULTS,
						async lazy() {
							const component = await import('@pages/roles/components/RolesVaults/RolesVaults');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.APPEARANCE,
						async lazy() {
							const component = await import('@pages/settings/appearance/Appearance');
							return { Component: component.default };
						},
					},
					// HIDDEN so far
					// {
					// 	path: SerafFlatRoute.CURRENCY,
					// 	async lazy() {
					// 		const component = await import('@pages/settings/currency/Currency');
					// 		return { Component: component.default };
					// 	},
					// },
					{
						path: SerafFlatRoute.SYSTEM,
						async lazy() {
							const component = await import('@pages/settings/system/System');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.CONNECTIONS,
						async lazy() {
							const component = await import('@pages/connections/Connections');
							return { Component: component.default };
						},
						children: [
							{
								index: true,
								async lazy() {
									const component = await import('@pages/connections/components/new-connection/NewConnection');
									return { Component: component.default };
								},
							},
							{
								path: SerafFlatRoute.CONNECTION_NEW,
								async lazy() {
									const component = await import('@pages/connections/components/new-connection/NewConnection');
									return { Component: component.default };
								},
							},
							{
								path: SerafFlatRoute.CONNECTION_NOTION,
								async lazy() {
									const component = await import('@pages/connections/components/notion/NotionSelector');
									return { Component: component.default };
								},
							},
							{
								path: SerafFlatRoute.CONNECTION_GDRIVE,
								async lazy() {
									const component = await import('@pages/connections/components/GDrive');
									return { Component: component.default };
								},
							},
							{
								path: SerafFlatRoute.CONNECTION_ONEDRIVE,
								async lazy() {
									const component = await import('@pages/connections/components/OneDrive');
									return { Component: component.default };
								},
							},
							{
								path: SerafFlatRoute.CONNECTION_SHAREPOINT,
								async lazy() {
									const component = await import('@pages/connections/share-point/SharePoint');
									return { Component: component.default };
								},
							},
							{
								path: SerafFlatRoute.CONNECTION_ACUMATICA,
								async lazy() {
									const component = await import('@pages/connections/acumatica/Acumatica');
									return { Component: component.default };
								},
							},
							{
								path: SerafFlatRoute.CONNECTION_GMAIL,
								async lazy() {
									const component = await import('@pages/connections/components/GMail');
									return { Component: component.default };
								},
							},
							{
								path: SerafFlatRoute.CONNECTION_ALANYTICS,
								async lazy() {
									const component = await import('@pages/connections/components/GAnalytics');
									return { Component: component.default };
								},
							},
						],
					},
					{
						path: SerafFlatRoute.HISTORY,
						async lazy() {
							const component = await import('@pages/history/History');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.TEMPLATES,
						async lazy() {
							const component = await import('@pages/templates/Templates');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.TEMPLATES_BUILDER,
						async lazy() {
							const component = await import('@pages/templates/TemplateBuilder');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.CUSTOM_RULES,
						async lazy() {
							const component = await import('@pages/custom-rules/CustomRules');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.RESOLVER,
						async lazy() {
							const component = await import('@pages/resolver/Resolver');
							return { Component: component.default };
						},
						children: [
							{
								index: true,
								async lazy() {
									const component = await import('@pages/resolver/components/library/Library');
									return { Component: component.default };
								},
							},
							{
								path: SerafFlatRoute.RESOLVER_LIBRARY,
								async lazy() {
									const component = await import('@pages/resolver/components/library/Library');
									return { Component: component.default };
								},
							},
							{
								path: SerafFlatRoute.RESOLVER_TYPE,
								async lazy() {
									const component = await import('@pages/resolver/components/types/Types');
									return { Component: component.default };
								},
							},
						],
					},
				],
			},
			{
				path: SerafFlatRoute.DATA_UPLOAD,
				async lazy() {
					const component = await import('@pages/documents-upload/DocumentsUploadV2');
					return { Component: component.default };
				},
			},
			{
				path: SerafFlatRoute.CHAT,
				async lazy() {
					const component = await import('@pages/chat-general/ChatGeneral');
					return { Component: component.default };
				},
				children: [
					{
						path: SerafFlatRoute.DASHBOARD,
						async lazy() {
							const component = await import('@layout/dashboard/Dashboard');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.TOPIC,
						async lazy() {
							const component = await import('@layout/chat/Chat');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.TOPIC_DOCUMENT,
						async lazy() {
							const component = await import('@layout/chat/Chat');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.DOCUMENT_DASHBOARD,
						async lazy() {
							const component = await import('@layout/dashboard-document/DashboardDoc');
							return { Component: component.default };
						},
					},
					{
						path: SerafFlatRoute.CHAT_WITH_VAULT,
						async lazy() {
							const component = await import('@layout/dashboard-vault/DashboardVault');
							return { Component: component.default };
						},
					},
				],
			},
		],
	},
	{
		path: '*',
		async lazy() {
			const component = await import('@pages/not-found/NotFound');
			return { Component: component.default };
		},
	},
];

const router = createBrowserRouter(routes);

export default router;

const DELAY_AFTER_RENDER_MS = 2000;
const TIME_GAP_MS = 500;

async function lazyRoute<T>(importFn: () => Promise<T>) {
	await importFn();
}

const loadChildren = async (routes: RouteObject[]): Promise<void> => {
	for (const route of routes) {
		if (route.lazy) {
			await lazyRoute(route.lazy);
			await wait(TIME_GAP_MS);
		}
		if (route.children?.length > 0) {
			loadChildren(route.children);
		}
	}
};

export const preloadLazyComponents = () => {
	setTimeout(() => {
		loadChildren(routes);
	}, DELAY_AFTER_RENDER_MS);
};
