if (process.env.IS_DEBUG) {
	require('preact/devtools');
}
import { h, render } from 'preact';
import { RouterProvider } from 'react-router-dom';
import router from './router';
import ImageBackground from '@layout/image-background/ImageBackground';
import Loader from '@elements/loader/Loader';

import '@styles/common.scss';
import '@styles/layout.scss';
import '@styles/color-schemes.scss';
import '@styles/root-variables.scss';
import { getEnvironment, useEnvironmentStore } from '@services/EnvService';
import CSSVariables from '@services/CSSVariablesService';
import 'overlayscrollbars/overlayscrollbars.css';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@elements/button/Button';

const rootElement: HTMLElement | null = document.getElementById('app');

// first load always make color scheme based on the system settings and night mode or saved in local storage
// next color change will be handled by the Root.tsx component when user's settings are laoded (day or night mode can be changed then according set by user in settings page)
getEnvironment().then((env) => {
	if (env) {
		const isDayColorMode = localStorage.getItem('is_day_color_mode') === 'true' || false;
		CSSVariables.update(CSSVariables.buildColorScheme(env.color_accent, isDayColorMode));
	}
});

const AppLoader = () => {
	const { loaded: envLoaded, error: envError } = useEnvironmentStore((state) => state);

	if (!envLoaded) {
		return (
			<div className="env-loader">
				<ImageBackground />
				{!envError ? <Loader /> : <span className="env-loader__error-message">{envError}</span>}
				{envError && (
					<Button secondary fullWidth={false} onClick={() => window.location.reload()}>
						Reload
					</Button>
				)}
			</div>
		);
	}

	return <RouterProvider router={router} />;
};

if (rootElement) {
	render(<AppLoader />, rootElement);
}
